import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core'
import { Drawer, MenuItem, MenuList, Toolbar } from '@material-ui/core';
import { Link, Routes, Route } from 'react-router-dom'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SignalTopPage from './top/SignalTopPage'
import SignalSpeechPage from './speech/SignalSpeechPage'


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {

  },
  drawerPaper: {

  },
  drawerContainer: {
    overflow: "auto",
    marginTop: 65,
    width: 160
  },
  content: {
    flexGrow: 1,
    marginTop: 65,
    padding: 0
  },
  body: {
    marginLeft: 0,
    marginRight: 0,
    padingLeft: 5,
    paddingRight: 5
  }
}));

interface GetBrandResponse {

}

interface Props {

}

const SignalPage: React.FC<Props> = (props: Props) => {

  const classes = useStyles()

  const params = useParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [windowSize, setWindowSize] = useState([0, 0])

  useEffect(() => {
    
  }, [])


  return (
    <div>
      <Drawer variant="permanent" className={classes.drawer} classes={{paper: classes.drawerPaper}}>
        <MenuList className={classes.drawerContainer}>
          <MenuItem component={Link} to="speech">Speech</MenuItem>
          <MenuItem component={Link} to="/policyEvaluation">Policy Evaluation</MenuItem>
        </MenuList>
      </Drawer>
      <main className={classes.content}>
        <Routes>
          <Route path="" element={<SignalTopPage />} />
          <Route path="speech" element={<SignalSpeechPage />} />
        </Routes>
      </main>
    </div>
  )
}

export default SignalPage
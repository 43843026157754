import { useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { AppBar, Tabs, Tab, Theme, makeStyles, Toolbar, Typography } from '@material-ui/core'
import './App.css'
import TopPage from './pages/top/TopPage'
import SignalPage from './pages/signal/SignalPage'


const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  link: {
    color: "#FFFFFF",
    textDecoration: "none",
    fontSize: 26,
    marginRight: 26
  },
  content: {
    flexGrow: 1,
    marginTop: 65,
    padding: 0
  }
}));


const App = () => {

  const [tabsValue, setTabsValue] = useState<number>(-1)

  useEffect(() => {
    //document.title = "User Profiler";
  }, []);

  const onChangeTabs = function(event: object, value: any) {
    setTabsValue(value)
  }

  const location = useLocation()
  if(location.pathname.startsWith("/signal/")) {
    if(tabsValue !== 0) {
      setTabsValue(0)
    }
  } else if(location.pathname.startsWith("/game/")) {
    if(tabsValue !== 1) {
      setTabsValue(1)
    }
  } else {
    if(tabsValue !== -1) {
      setTabsValue(-1)
    }
  }

  const classes = useStyles();
  
  return (
    <div className="App">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography>
            <Link className={classes.link} to="/">Orfeon Playground</Link>
          </Typography>
          <Tabs value={tabsValue} onChange={onChangeTabs}>
            <Tab label="Signal" component={Link} to={"/signal/"} />
            <Tab label="Game" component={Link} to={"/game/"} />
          </Tabs>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Routes>
          <Route path="/" element={<TopPage />} />
          <Route path="/signal/*" element={<SignalPage />} />
        </Routes >
      </div>
    </div>
  )
}

export default App
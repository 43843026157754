import { Button, Container, GridList, GridListTile, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  gridList: {
    width: 800,
    //height: 500
  },
  tile: {
    backgroundColor: "#f0f0f0",
    margin: 1
  }
}))

const SignalTopPage: React.FC<any> = (props) => {

  const size = 3;
  const blocks = Array.from(Array(size ** 2).keys())

  const classes = useStyles()
  
  return (
    <div>
      <Container>
        <Button>button</Button>  
      </Container>
      <Container>
        
        <GridList className={classes.gridList} cols={size}>
          {blocks.map((block) => {
            return (<GridListTile key={block} cols={1} rows={1} className={classes.tile} style={{width: "80px", height: "80px"}}><p>a</p></GridListTile>);
          })}          
        </GridList>
      </Container>
    </div>
  )
}

export default SignalTopPage
